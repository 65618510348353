import React from 'react';
import {format} from "date-fns"

export const PostHead = ({frontmatter}) => {
  const {
    title,
    description,
  } = frontmatter;

  const date = new Date(frontmatter.date)

  return (
    <header>
      <h1 className={"text-xl"}>
        {title}
      </h1>

      <h2>
        {description}
      </h2>

      <span>
        Posted&nbsp;
        <time dateTime={format(date, 'yyyy-MM-dd')}>
          {format(date, 'd MMM yyy')}
        </time>
      </span>
    </header>
  )
}

export default {PostHead}
